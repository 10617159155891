export default {
    colors: {
        first: '#2D93AD',
        second: '#DBD56E',
        third: '#88AB75',
        fourth: '#7D7C84',
        fifth: '#DE8F6E',
        fontColor: '#7D7C84'
    },
    fontSize: 16,
    lineHeight: 1.5
};;